<template>
  <div>
    <StoreCarousel />
    <Categories />
  </div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import Categories from "./LandingPageItems/Categories.vue";
import StoreCarousel from "./StoreCarousel/StoreCarousel.vue";
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'StoreLandingPage',
  components: {
    Categories,
    StoreCarousel,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      productCategories(state) {
        return state.storeProducts.productCategories;
      },
      currUser(state) {
        return state.login.currUser;
      },
    })
  },
  methods: {
    ...mapActions({
      setShoppingCartItems: "storeProducts/setShoppingCartItems"
    }),
    ...mapMutations({
      setSelectedCategory: "storeProducts/SET_SELECTED_CATEGORY",
      setSelectedSubCategory: "storeProducts/SET_SELECTED_SUB_CATEGORY",
    }),
    onClick() { },
  },
  async mounted() {
    await this.setSelectedCategory(null);
    await this.setSelectedSubCategory(null);
    localStorage.setItem("category", null)
    localStorage.setItem("subCategory", null)
    const userId = this.currUser ? this.currUser.id : null;
    await this.setShoppingCartItems({ userId });
  },
};
</script>

<style scoped></style>
  