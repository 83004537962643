<template>
  <div class="image_cont" v-if="productCategories && productCategories[0]">
    <div>
      <Carousel
      :perPageCustom="[
        [200, 1],
        [768, 1],
      ]"
      paginationActiveColor="#E87613"
      paginationColor="#D9D9D9"
      :paginationEnabled="false"
      v-bind:autoplay="true" v-bind:loop="true" v-bind:autoplayTimeout="3000" v-bind:autoplayHoverPause="false"
      >
        <slide v-for="(obj, indx) in productCategories[0].banner" :key="indx" class="image_x">
          <Banner :val="obj" :category="productCategories[0]" :style="{'background-image': `url(${obj.imageUrl})`}" class="banner_img"/>
        </slide>
      </Carousel>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import Banner from "./Banner.vue";

export default {
  components: {
    Carousel,
    Banner,
    Slide,
  },
  data() {
    return {
      bannerContent: [
        {
          bannerHeading: "Unveil the Richness of India Through Our Unique Collection",
          bannerDescription: "Order now to get it delivered at your doorsteps",
        },
      ],
    };
  },

  methods: {
  },

  computed: {
    ...mapState({
      selectedCategory(state) {
        return state.storeProducts.selectedCategory;
      },
      productCategories: state => {
        return state.storeProducts.productCategories;
      }
    }),
  },
};
</script>
<style scoped>
.image_cont {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}
.image_x {
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/yicimages/store/banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.banner_img{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}
/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
  .image_x {
    display: visible;
    overflow: visible;
  }
}
</style>