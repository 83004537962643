<template>
    <div class="root_banner">
      <div class="h_">{{ val.bannerHeading }}</div>
      <div class="p_">{{ val.bannerDescription }}</div>
      <div class="bttn">
        <b-button
          class="bttn1"
          variant="no-color"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="viewItemsAccordingToCategory(category)"
        >
          Shop Now
        </b-button>
      </div>
    </div>
</template>
<script>
import { BModal, VBModal, BAlert } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import {mapState, mapActions} from 'vuex';
import {
  BButton,
  BImg,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: ["val", "category"],
  data() {
    return {};
  },
  computed: mapState({
    selectedCategory(state) {
      return state.storeProducts.selectedCategory;
    },
  }),
  mounted() {},
  methods: {
    onClick() {},
    async viewItemsAccordingToCategory(selectedCategory){
      await this.setSelectedCategoryProducts({selectedCategory: selectedCategory})
      localStorage.setItem("category", JSON.stringify(selectedCategory))
      this.$router.push({path:`/store/category/${selectedCategory.name}`, query: {category: selectedCategory}})
    },
    ...mapActions({
      setSelectedCategoryProducts: "storeProducts/setSelectedCategoryProducts"
    }),
  },
};
</script>
<style scoped>
.root_banner {
  padding: 8rem 0rem 0rem 8rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.h_ {
  font-family: "Shadows Into Light";
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
}
.p_ {
  width: 600px;
  font-weight: 400;
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 3rem;
}
.bttn1 {
  background: #e87613;
  color: #ffffff;
  padding: 1rem;
  min-width: 12rem;
  min-height: 3rem;
}
.bttn2 {
  border: 2px solid #e87613;
  background: transparent;
  color: #e87613;
  width: 185px;
  height: 40px;
}
.bttn {
  display: flex;
  gap: 2rem;
}
</style>